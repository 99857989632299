import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import { Star } from "lucide-react";

const ChooseYourJourney = () => {
  const [activeFilter, setActiveFilter] = useState("All Courses");

  const courseOptions = [
    {
      title: "Machine Learning Fundamentals",
      duration: "8 weeks",
      price: "$599",
      rating: 4.7,
      filters: ["All Courses", "Data Science"],
      image:
        "https://verpex.com/assets/uploads/images/blog/How-to-become-a-Backend-Developer.jpg?v=1665484477",
      description:
        "Comprehensive introduction to machine learning algorithms, featuring Python-based practical projects and real-world applications.",
    },
    {
      title: "Cloud Computing Mastery",
      duration: "6 weeks",
      price: "$499",
      rating: 4.5,
      filters: ["All Courses", "Cloud Technologies"],
      image:
        "https://verpex.com/assets/uploads/images/blog/How-to-become-a-Backend-Developer.jpg?v=1665484477",
      description:
        "Deep dive into AWS, Azure, and Google Cloud platforms with hands-on lab exercises and certification preparation.",
    },
    {
      title: "Cybersecurity Essentials",
      duration: "7 weeks",
      price: "$549",
      rating: 4.8,
      filters: ["All Courses", "Cybersecurity"],
      image:
        "https://verpex.com/assets/uploads/images/blog/How-to-become-a-Backend-Developer.jpg?v=1665484477",
      description:
        "Learn network security, ethical hacking, and defensive strategies with industry-standard tools and techniques.",
    },
    {
      title: "Full Stack Web Development",
      duration: "12 weeks",
      price: "$799",
      rating: 4.6,
      filters: ["All Courses", "Web Development"],
      image:
        "https://verpex.com/assets/uploads/images/blog/How-to-become-a-Backend-Developer.jpg?v=1665484477",
      description:
        "Comprehensive program covering frontend and backend technologies, including React, Node.js, and database management.",
    },
  ];

  const filterOptions = [
    "All Courses",
    "Data Science",
    "Cloud Technologies",
    "Cybersecurity",
    "Web Development",
  ];

  const filteredCourses = courseOptions.filter((course) =>
    course.filters.includes(activeFilter)
  );

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        color={index < Math.floor(rating) ? "gold" : "gray"}
        fill={index < Math.floor(rating) ? "gold" : "none"}
        size={20}
      />
    ));
  };

  return (
    <Container className="my-5">
      <h1 className="text-center mb-5">Choose Your Journey</h1>
      <Row className="justify-content-center">
        <Col md={12}>
          <div className="d-none d-md-flex justify-content-center gap-2 mb-4">
            {filterOptions.map((filter, index) => (
              <Button
                key={index}
                variant={
                  activeFilter === filter ? "warning" : "outline-warning"
                }
                className={`mr-2 ${
                  activeFilter === filter ? "text-white" : ""
                }`}
                onClick={() => setActiveFilter(filter)}
              >
                {filter}
              </Button>
            ))}
          </div>
          <div className="d-md-none mb-4">
            <Dropdown>
              <Dropdown.Toggle
                variant="warning"
                className="text-white"
                id="filter-dropdown"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {activeFilter}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                {filterOptions.map((filter, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => setActiveFilter(filter)}
                    active={activeFilter === filter}
                  >
                    {filter}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        {filteredCourses.map((course, index) => (
          <Col key={index} md={6} lg={3} className="mb-4">
            <Card className="h-100 border-0 shadow">
              <Card.Img variant="top" src={course.image} alt={course.title} />
              <Card.Body className="d-flex flex-column justify-content-between">
                <div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-grow-1">
                      <Card.Title>{course.title}</Card.Title>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    {renderStars(course.rating)}
                    <span className="ml-2">({course.rating})</span>
                  </div>
                  <Card.Text>Duration: {course.duration}</Card.Text>
                  <Card.Text>{course.description}</Card.Text>
                </div>
                <div className="d-flex flex-row justify-content-between align-content-center mt-1">
                  <span className="font-weight-bold h6">{course.price}</span>
                  <Button variant="warning" className="text-white">
                    View
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ChooseYourJourney;
