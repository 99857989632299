import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  GraduationCap,
  Globe,
  Users,
  BookOpen,
  Target,
  Rainbow,
} from "lucide-react";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 },
  },
};

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <Container className="py-5">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Row className="justify-content-center text-center mb-5">
          <Col md={10}>
            <motion.h2 className="display-4 mb-4" variants={itemVariants}>
              About Devupvers
            </motion.h2>
            <motion.p className="lead text-muted" variants={itemVariants}>
              Revolutionizing learning through innovative, accessible digital
              education.
            </motion.p>
          </Col>
        </Row>

        <Row className="g-4 mb-5">
          <Col md={6}>
            <motion.div variants={itemVariants}>
              <div className="d-flex align-items-center mb-3">
                <Globe className="me-3 text-primary" size={40} />
                <h3>Our Vision</h3>
              </div>
              <p className="text-muted">
                Breaking geographical and socio-economic barriers to provide
                quality education for all, regardless of background or learning
                style.
              </p>
            </motion.div>
          </Col>
          <Col md={6}>
            <motion.div variants={itemVariants}>
              <div className="d-flex align-items-center mb-3">
                <BookOpen className="me-3 text-success" size={40} />
                <h3>What We Do</h3>
              </div>
              <p className="text-muted">
                Offering comprehensive digital learning tools and resources,
                from foundational subjects to specialized professional
                development courses curated by industry experts.
              </p>
            </motion.div>
          </Col>
        </Row>

        <Row className="g-4 mb-5">
          <Col md={6}>
            <motion.div variants={itemVariants}>
              <h4 className="mb-3">
                <Target className="me-2 text-warning" size={24} />
                Our Platform Features
              </h4>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <Users className="me-2 text-info" size={20} />
                  Interactive Online Courses with real-time engagement
                </li>
                <li className="mb-2">
                  <Rainbow className="me-2 text-danger" size={20} />
                  Adaptive Learning with AI-powered personalization
                </li>
                <li>
                  <GraduationCap className="me-2 text-success" size={20} />
                  Flexible Self-Paced Learning Paths
                </li>
              </ul>
            </motion.div>
          </Col>
          <Col md={6}>
            <motion.div variants={itemVariants}>
              <h4 className="mb-3">Our Core Values</h4>
              <ul className="list-unstyled">
                <li className="mb-2">
                  • <strong>Accessibility</strong>: Education available anytime,
                  anywhere
                </li>
                <li className="mb-2">
                  • <strong>Community</strong>: Collaborative learning
                  environment
                </li>
                <li>
                  • <strong>Quality</strong>: Expert-verified, up-to-date
                  content
                </li>
              </ul>
            </motion.div>
          </Col>
        </Row>

        <Row className="text-center">
          <Col>
            <motion.div variants={itemVariants}>
              <h3 className="mb-3">Ready to Transform Your Learning?</h3>
              <Button
                variant="primary"
                size="lg"
                onClick={() => navigate("/contact")}
                aria-label="Start Learning Journey"
              >
                Get Started
              </Button>
            </motion.div>
          </Col>
        </Row>
      </motion.div>
    </Container>
  );
};

export default AboutUs;
